@import './mat-side-nav';
@import './mat-checkbox';
@import './mat-button';
@import './mat-toogle';
@import './mat-table';
@import './mat-poput';
@import './mat-icons';
@import './mat-popup';
@import './mat-menu';
@import './mat-form';
@import './mat-cart';
@import './mat-tab';

.cdk-overlay-container {
  z-index: $app_search_result !important;
}

.mat-mdc-menu-item {
  font-size: var(--proximilar-font-size);
}
