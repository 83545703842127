$app_search_result: 9999;
$app_loading_z: 1000;
$app_header_z: 1999;

:root {
  --proximilar-info-block-width: 1000px;
  --proximilar-font-size: 0.8125rem;
  --proximilar-border-radius: 4px;
  --proximilar-padding: 1.5em;
  --proximilar-progress-bar-size: 0.4rem;
}

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
