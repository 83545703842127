
.mat-mdc-tab-link-container {
  .mat-mdc-tab-links {
    .mat-mdc-tab-link {
      height: calc(2 * var(--proximilar-padding));
      text-decoration: none;
      text-transform: uppercase;
      font-size: var(--proximilar-font-size);
      box-shadow: -24px -9px 21px -10px rgb(17 157 86 / 0.15) inset;

      .tab-btn {
        margin: 0 calc(-1.5 * var(--proximilar-padding)) 0 var(--proximilar-padding);
        height: calc(2 * var(--proximilar-padding));
        font-size: 0.9em;
      }
    }
  }
}

.mat-tab-nav-bar-wrapper {
  max-width: 100%;
  font-size: var(--proximilar-font-size);

  .mat-tab-nav-bar-block {
    overflow: hidden;
  }

  .actions-wrapper {
    position: absolute;
    > * {
      height: calc(2 * var(--proximilar-padding));
      width: calc(2 * var(--proximilar-padding));
      font-size: var(--proximilar-font-size);
    }
  }
}

.nav-link-group {
  --mat-tab-header-pagination-icon-color: currentColor;

  .mat-mdc-tab-header-pagination {
    color: currentColor;
  }

  .mat-mdc-tab-link-container {
    border: none;

    .mat-mdc-tab-links {
      gap: 1em;
    }
  }
}
