/* stylelint-disable selector-class-pattern */

@import 'src/styles/variables';

.form {
  .form-group {
    padding-bottom: 0.5rem;

    .mat-mdc-form-field {
      display: block;
    }

    .mat-mdc-slide-toggle {
      margin-bottom: 0.5rem;
    }
  }

  .bottom-group {
    display: flex;
    align-items: center;

    &.group-between {
      justify-content: space-between;
    }
  }
}

.mat-mdc-form-field {
  color: var(--proximilar-font-color);


  --mdc-filled-text-field-focus-label-text-color: var(--proximilar-focus-label-text-color);
  --mdc-filled-text-field-focus-active-indicator-color: var(--proximilar-focus-label-text-color);
  --mdc-filled-text-field-caret-color: var(--proximilar-focus-label-text-color);
  --mdc-filled-text-field-hover-label-text-color: var(--proximilar-hover-label-text-color);

  .mdc-text-field {
    &:not(.mdc-text-field--disabled) {
      background-color: var(--proximilar-form-control-bg-color);

      .mdc-text-field__input, .mdc-floating-label {
        color: currentColor;
      }

      .mdc-text-field__input {
        &:focus {
          @include placeholder {
            color: currentColor;
            opacity: 0.3;
          }
        }
      }

      .mdc-floating-label {
        opacity: 0.6;
      }
    }

    &.mdc-text-field--disabled {
      background-color: var(--proximilar-form-control-disabled-bg-color);

      .mdc-text-field__input, .mdc-floating-label {
        color: currentColor;
        opacity: 0.38;
      }
    }

    .mdc-line-ripple {
      &:before {
        border-bottom-color: currentColor !important;
        opacity: 0.4;
      }
    }
  }

  .mat-mdc-select-arrow {
    color: currentColor !important;
    opacity: 0.55;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: var(--proximilar-bg-reverse-color);
  }

  &:hover {
    .mdc-text-field {
      &:not(.mdc-text-field--disabled) {
        .mdc-line-ripple {
          &:before {
            opacity: 0.6;
          }
        }
      }
    }
  }

  &.form-field-reverse {
    color: var(--proximilar-font-reverse-color);

    .mdc-text-field--filled {
      &:not(.mdc-text-field--disabled) {
        background-color: var(--proximilar-form-control-reverse-bg-color);

        .mdc-text-field__input {
          // color: currentColor;
        }
      }

      &.mdc-text-field--disabled {
        background-color: var(--proximilar-form-control-reverse-disabled-bg-color);
      }
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: var(--proximilar-bg-color);
    }
  }

  &.form-field-no-error {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &.form-field-sm {
    font-size: 0.9rem !important;
    --mdc-filled-text-field-label-text-size: 1em;

    .mat-mdc-form-field-icon-prefix {
      button {
        top: 0 !important;
        padding: 0;
        width: 2rem;
        height: 2rem;
      }
    }

    .mat-mdc-icon-button {
      &.mat-mdc-button-base {
        padding: 0.3rem;
        width: 2rem;
        height: 2rem;
      }
    }

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding: 0.5rem 0 0;
        min-height: 2.5rem;
        top: 0.3rem;

        .mat-mdc-floating-label {
          top: 0.9rem;
        }
      }
    }
  }

  &.form-field-xs {
    font-size: 0.7rem !important;

    .mat-mdc-text-field-wrapper {
      padding: 0 0.5rem;
    }

    .mat-mdc-form-field-icon-prefix {
      button {
        top: 0 !important;
        padding: 0;
        width: 1rem;
        height: 1rem;
      }
    }

    .mat-mdc-icon-button {
      &.mat-mdc-button-base {
        padding: 0.1rem;
        width: 1rem;
        height: 1rem;

        .mat-icon {
          height: 1.1rem;
          width: 1.1rem;
          font-size: 0.8rem;
        }

        .mat-mdc-button-touch-target {
          height: 150%;
          width: 150%;
        }
      }
    }

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding: 0.5rem 0 0;
        min-height: 1.7rem;
        top: 0.18rem;

        .mat-mdc-floating-label {
          top: 0.7rem;
        }
      }
    }
  }
}

.cdk-overlay-pane {
  .mdc-menu-surface {
    background-color: var(--proximilar-bg-color);
  }
}

.mat-suffix-wrapper {
  display: flex;
  align-items: center;
  color: currentColor;
}
