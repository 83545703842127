.seven-day-popup {
  .mat-mdc-dialog-container {
    padding: 0;
    overflow: hidden;
  }

  .mat-mdc-dialog-content {
    display: flex;
    overflow: hidden;
  }
}
