.tooltip.app-tooltip {
  padding: 1em;
  text-align: left;
  color: var(--proximilar-font-color);
  font-size: var(--proximilar-font-size);
  background-color: var(--proximilar-bg-color);

  &.tooltip-right {
    &:after {
      border-color: var(--proximilar-bg-color);
    }
  }

  .tooltip-title {
    margin: 0 0 1em;
  }
}
