@mixin block-shadow {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 0.2), 0 2px 2px 0 rgb(0 0 0 / 0.14), 0 1px 5px 0 rgb(0 0 0 / 0.12);
}

@mixin clearBlock($display: null) {
  @if $display { display: $display; }
  margin: 0;
  padding: 0;
  @content;
}

@mixin threeDots($width: 100%) {
  @if $width {
    width: $width;
    max-width: $width;
  }
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @content;
}

@mixin placeholder {
  &::placeholder { @content; }
}

@mixin dotInfo($color: var(--proximilar-form-control-disabled-color)) {
  display: inline-block;
  min-width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: $color;
}

@mixin rectangleInfo($bg: var(--proximilar-warn-color)) {
  display: inline-block;
  min-width: 0.5rem;
  height: 0.3rem;
  background-color: $bg;
}
