
.mat-mdc-card-content {
  font-size: var(--proximilar-font-size);
}

.mat-mdc-card {
  display: flex !important;
  flex-direction: column;
  gap: var(--proximilar-padding);

  // TODO: styles. Find better way
  background-color: var(--proximilar-bg-color) !important;

  .mat-mdc-card-header {
    margin-top: 0;
    color: var(--proximilar-accent-color);

    .mat-mdc-card-header-text {
      margin-left: 0;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      width: 100%;

      &:empty { display: none; }

      .mat-mdc-card-title, .mat-mdc-card-subtitle { margin: 0; }

      .mat-mdc-card-subtitle {
        &.lg-size-subtitle {
          --mat-card-subtitle-text-size: 1.3em
        }
      }

      .mat-mdc-card-title {
        display: flex;
        align-items: center;
        font-size: 1.75rem;
        font-weight: 500;

        @media only screen and (max-width: $md) {
          font-size: 1.5rem;
        }
      }
    }
  }

  &.sub-section {
    .mat-mdc-card-header {
      .mat-mdc-card-header-text {
        .mat-mdc-card-title {
          font-size: 1.35rem;

          @media only screen and (max-width: $md) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
