/* stylelint-disable selector-class-pattern */

.mat-mdc-checkbox {
  .mdc-form-field {
    color: currentColor !important;
  }

  .mdc-checkbox {
    .mdc-checkbox__native-control:enabled:not(:checked, :indeterminate, [data-indeterminate='true'])~.mdc-checkbox__background {
      border-color: currentColor !important;
      opacity: 0.65;
    }
  }

  &.checkbox-no-padding {
    --mdc-checkbox-state-layer-size: 1.125rem;

    .mdc-form-field {
      .mdc-checkbox {
        padding: 0;

        .mat-mdc-checkbox-touch-target {
          width: var(--mdc-checkbox-state-layer-size);
          height: var(--mdc-checkbox-state-layer-size);
        }
      }
    }
  }
}

// .mat-mdc-checkbox {
//   /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//   .mat-checkbox-inner-container {
//     border: 1px solid currentColor;
//     border-radius: 4px;
//   }
//   /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//   .mat-checkbox-label {
//     font-size: var(--proximilar-font-size);
//   }
//   /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//   /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//   &.mat-checkbox-checked {
//     /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//     .mat-checkbox-inner-container {
//       border-color: transparent;
//     }
//   }
// }
