/* stylelint-disable-next-line selector-type-no-unknown */
mwlresizable {
  box-sizing: border-box;
}

.resize-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .table-scroll-container {
    max-height: 100%;
    flex: 1;
  }
}

.resize-handle-bottom {
  position: absolute;
  bottom: -0.3rem;
  height: 0.3rem;
  width: 100%;
  cursor: row-resize;
  background-color: var(--proximilar-border-color);
}
