@import './theme/colors';
@import './variables';
@import './mixins';

.rectangle-info-wrapper {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.rectangle-info {
  @include rectangleInfo;

  &.rectangle-info-primary {
    @include rectangleInfo(var(--proximilar-primary-color));
  }
}

.earning-release-inline-wrapper {
  display: inline-flex;
  gap: 3px;
}

.earning-release {
  @include dotInfo;

  &.release-soon {
    @include dotInfo(var(--proximilar-primary-color));
  }
}

.announce-view {
  @include dotInfo;

  &.announce-expired {
    @include dotInfo(var(--proximilar-form-control-disabled-color));
  }

  &.announce-expired-soon {
    @include dotInfo(var(--proximilar-primary-color));
  }
}

.announce-view-wrapper {
  .announce-view { background-color: var(--proximilar-warn-color); }

  &.announce-expired {
    .announce-view { background-color: var(--proximilar-form-control-disabled-color); }
  }

  &.announce-expired-soon {
    .announce-view { background-color: var(--proximilar-primary-color); }
  }
}

.announce-view-inline-wrapper {
  display: inline-flex;
  gap: 3px;
}

.page-wrapper {
  margin: 0 auto;
  width: 100%;

  @media only screen and (min-width: $xxl) {
    width: 89rem;
  }
}

.mat-mdc-card.page-card {
  height: 100%;
  max-width: 100%;
  padding: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;

  .mat-mdc-card-header, .mat-mdc-card-content {
    padding: 0;
  }

  .page-wrapper {
    & > .mat-mdc-card-header {
      margin: 0 0 1rem;
    }
  }
}

router-outlet {
  display: none;
}
