@import './styles/styles';
@import './styles/theme/colors';

html, body { height: 100%; }

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: var(--proximilar-font-color);
  font-size: var(--proximilar-font-size);
}

a, button {
  cursor: pointer;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

a, a:focus, a:active, a:visited, a:hover {
  color: var(--proximilar-accent-color);
}

.app-container { padding: 1rem; }

.text-pre-line { white-space: pre-line; }
.text-center { text-align: center; }
.text-primary { color: var(--proximilar-primary-color) !important; }
.text-accent { color: var(--proximilar-accent-color) !important; }
.text-warn { color: var(--proximilar-warn-color) !important; }
.text-error { color: var(--proximilar-error-color) !important; }
.no-wrap { white-space: nowrap; }

.info-table-block {
  .item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 1.5rem;
    max-width: var(--proximilar-info-block-width);
    border-bottom: 1px solid var(--proximilar-border-color);

    &:first-child {
      border-top: 1px solid var(--proximilar-border-color);
    }

    &.item-form-group {
      display: block;
      border-bottom: none;
    }
  }
}

.in-request-block-wrapper {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--proximilar-bg-color);
    opacity: 0.7;
    z-index: $app_loading_z;
  }
}
