@use 'sass:map';
@use '@angular/material' as mat;

@use './m3-dark-theme';
@use './m3-light-theme';

// Light theme
// Un comment below to use theme created by ng add @angular-material
// $theme: $angular-material-3-light-theme;

// Light Theme created by ng generate @angular-material:m3-theme
$theme: m3-light-theme.$light-theme;

// Dark theme
// Un-comment below to use dark theme using @angular/material
// $darkTheme: $angular-material-3-darkTheme;

// Dark Theme created by ng generate @angular-material:m3-theme
$darkTheme: m3-dark-theme.$darkTheme;

$formControlBg: rgba(mat.get-theme-color($theme, primary, 90), 0.04);
$formControlDisabledBg: rgba(mat.get-theme-color($theme, neutral, 90), 0.04);
$formControlDisabledColor: rgba(mat.get-theme-color($darkTheme, neutral, 10), 0.38);
$formControlFocusLabelColor: rgba(mat.get-theme-color($theme, primary, 60), 1);
$formControlHoverLabelColor: rgba(mat.get-theme-color($darkTheme, neutral-variant, 40), 1);

$formControlDarkBg: rgba(mat.get-theme-color($darkTheme, neutral, 10), 1);
$formControlDarkDisabledColor: rgba(mat.get-theme-color($darkTheme, neutral, 90), 0.38);
$formControlDarkDisabledBg: rgba(mat.get-theme-color($darkTheme, neutral, 90), 0.04);

$bgLight: rgba(mat.get-theme-color($theme, neutral, 99), 1);
$bgDark: rgba(mat.get-theme-color($darkTheme, neutral, 6), 1);
$textLight: rgba(mat.get-theme-color($darkTheme, neutral, 90), 1);
$textDark: rgba(mat.get-theme-color($theme, neutral, 10), 1);

:root {
  --proximilar-primary-color: #{rgba(mat.get-theme-color($theme, primary), 1)};
  --proximilar-primary-opacity-color: #{rgba(mat.get-theme-color($theme, primary), 0.1)};

  --proximilar-accent-color: #{rgba(mat.get-theme-color($theme, tertiary), 1)};
  --proximilar-accent-light-color: #{rgba(mat.get-theme-color($theme, neutral, 96), 0.5)};

  --proximilar-warn-color: #{rgba(mat.get-theme-color($theme, error), 1)};
  --proximilar-error-color: #{rgba(mat.get-theme-color($theme, error, 40), 1)};
  --proximilar-error-light-color: #{rgba(mat.get-theme-color($theme, error, 60), 1)};

  // --proximilar-grey-popup-title-color: #{map.get($mat-grey, 300)};
  // --proximilar-font-color: #{$light-base-color};

  --proximilar-font-color: #{$textDark};
  --proximilar-font-reverse-color: #{$textLight};

  --proximilar-bg-color: #{$bgLight};
  --proximilar-bg-reverse-color: #{$bgDark};
  --proximilar-border-color: #{rgba(mat.get-theme-color($theme, neutral-variant, 80), 1)};

  --proximilar-scroll-bg-color: #{rgba(mat.get-theme-color($theme, neutral, 98), 1)};
  --proximilar-scroll-thumb-color: var(--proximilar-primary-color);

  --proximilar-chart-bg-color: var(--proximilar-accent-light-color);
  --proximilar-chart-bg-group1-color: #44b1ab;
  --proximilar-chart-line-color: #44b1ab;
  --proximilar-chart-line-info-color: #2d0b78;
  --proximilar-chart-tooltip-color: #4086d7;
  --proximilar-chart-info1-color: #b685ff;
  --proximilar-chart-info2-color: #ffa67d;
  --proximilar-chart-info3-color: #C4515E;
  --proximilar-chart-info4-color: #D5A988;
  --proximilar-chart-info5-color: #4086d7;
  --proximilar-chart-info6-color: #009B75;
  --proximilar-chart-info7-color: #FF8691;
  --proximilar-chart-info8-color: #009AB5;

  --proximilar-form-control-bg-color: #{$formControlBg};
  --proximilar-form-control-disabled-color: #{$formControlDisabledColor};
  --proximilar-form-control-disabled-bg-color: #{$formControlDisabledBg};
  --proximilar-form-control-reverse-bg-color: #{$formControlDarkBg};
  --proximilar-form-control-reverse-disabled-bg-color: #{$formControlDarkDisabledBg};

  --proximilar-focus-label-text-color: #{$formControlFocusLabelColor};
  --proximilar-hover-label-text-color: #{$formControlHoverLabelColor};
  --proximilar-hover-label-text-color: #{rgba(mat.get-theme-color($darkTheme, neutral-variant, 40), 1)};
}

.dark-mode {
  --proximilar-primary-color: #{rgba(mat.get-theme-color($darkTheme, primary), 1)};
  --proximilar-primary-opacity-color: #{rgba(mat.get-theme-color($darkTheme, primary), 0.1)};

  --proximilar-accent-color: #{rgba(mat.get-theme-color($darkTheme, tertiary), 1)};
  --proximilar-accent-light-color: #{rgba(mat.get-theme-color($darkTheme, neutral, 10), 1)};

  --proximilar-warn-color: #{rgba(mat.get-theme-color($darkTheme, error), 1)};
  --proximilar-error-color: #{rgba(mat.get-theme-color($darkTheme, error, 40), 1)};
  --proximilar-error-light-color: #{rgba(mat.get-theme-color($darkTheme, error, 60), 1)};

  --proximilar-font-color: #{$textLight};
  --proximilar-font-reverse-color: #{$textDark};
  --proximilar-border-color: #{rgba(mat.get-theme-color($darkTheme, neutral-variant, 30), 1)};

  --proximilar-bg-color: #{$bgDark};
  --proximilar-bg-reverse-color: #{$bgLight};

  // --proximilar-bg-reverse-color: var(--proximilar-font-color);

  --proximilar-scroll-bg-color: var(--proximilar-accent-light-color);

  --proximilar-chart-bg-color: var(--proximilar-accent-light-color);
  --proximilar-chart-line-info-color: #dceaf1;
  --proximilar-chart-tooltip-color: #fee181;

  --proximilar-form-control-bg-color: #{$formControlDarkBg};
  --proximilar-form-control-disabled-color: #{$formControlDarkDisabledColor};
  --proximilar-form-control-disabled-bg-color: #{$formControlDarkDisabledBg};
  --proximilar-form-control-reverse-bg-color: #{$formControlBg};
  --proximilar-form-control-reverse-disabled-bg-color: #{$formControlDisabledBg};
}
