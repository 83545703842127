.app-side-nav {
  .mat-expansion-panel {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  .mat-mdc-list-item {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mat-mdc-list-base .mat-list-option .mat-list-item-content {
      padding: 0;

      .nav-link {
        padding: 0 1rem;
      }
    }
  }

  .nav-group-item {
    .mat-expansion-panel-content {
      .nav-link {
        padding: 0 1rem 0 1.5rem;
      }
    }
  }
}
