.mat-mdc-dialog-title {
  display: flex !important;
  align-items: center;
  gap: 0.5rem;
  color: var(--proximilar-grey-popup-title-color);

  .mat-icon {
    margin-top: 3px;
  }
}
