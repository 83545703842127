.mat-mdc-button-base, .mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-icon-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button, .mat-mdc-fab, .mat-mdc-mini-fab {
  font-size: var(--proximilar-font-size);

  // &.no-margin {
  //   margin: 0;
  // }
}

.mat-mdc-button-base {
  &.mat-mdc-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &.mat-icon-button-sm {
      width: 2rem;
      height: 2rem;

      .mat-mdc-button-touch-target {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}




// .mat-mdc-button-base {
//   .mat-icon ~ * {
//     margin-left: 1rem;
//   }

//   &.three-dots-btn {
//     /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
//     .mat-button-wrapper {
//       display: flex;
//       align-items: center;
//       width: 100%;
//     }
//   }

//   &.clear-btn {
//     /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
//     .mat-button-wrapper {
//       padding: 0;
//     }
//   }
// }
