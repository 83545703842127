@import '../../mixins';

.table-scroll-container {
  width: 100%;
  max-width: 100%;
  overflow: auto;

  &:not(.full-height-table) {
    max-height: 400px;
  }
}

mat-cell {
  box-sizing: content-box;
}

.mat-mdc-table {
  width: 100%;
  background-color: transparent !important;
  border-top: 1px solid var(--proximilar-border-color) !important;

  /* stylelint-disable */
  .mat-mdc-header-row .mat-mdc-header-cell, .mat-mdc-row .mat-mdc-cell {
    &.lg-column { min-width: 140px; max-width: 140px; }
    &.md-column { min-width: 100px; max-width: 100px; }
    &.sm-column { min-width: 80px; max-width: 80px; }
    &.xs-column {min-width: 50px; max-width: 50px; }
  }
  /* stylelint-enabled */

  .mat-mdc-header-row {
    background-color: var(--proximilar-bg-color);
    min-height: 3rem;

    .mat-mdc-header-cell {
      padding: 0 0.55rem;
      min-width: 120px;
      font-size: 0.75rem;
      white-space: normal;
      box-sizing: border-box;

      &:not(.no-border) {
        border-left: 1px solid var(--proximilar-border-color);
        border-right: 1px solid var(--proximilar-border-color);
      }

      &.action-header-cell, &.info-header-cell {
        min-width: 30px;
        max-width: 30px;

        &:not(.mat-sort-header) {
          padding: 0;
          justify-content: center;


        }

        .mat-mdc-button {
          width: auto;
          margin-left: 1.5rem;
        }
      }

      .mat-sort-header-container {
        gap: 6px;

        .mat-sort-header-content {
          text-align: inherit;
        }

        .mat-sort-header-arrow {
          margin: 0;
        }
      }

      &.text-right {
        justify-content: flex-end;
        text-align: right;

        .mat-sort-header-container {
          flex-direction: row-reverse;
        }
      }

      &.text-center {
        justify-content: center;
        text-align: center;

        .mat-sort-header-container {
          justify-content: center;
        }
      }
    }
  }

  .mat-mdc-row, .mat-mdc-footer-row {
    min-height: 2.5rem;
  }

  .mat-mdc-row {
    .mat-mdc-cell, .mat-mdc-footer-cell {
      font-size: var(--proximilar-font-size);
    }

    .mat-mdc-cell {
      position: relative;
      padding: 0 0.55rem;
      min-width: 120px;

      // text-align: center;
      z-index: 0;
      box-sizing: border-box;

      &.action-cell, &.info-cell {
        padding: 0.25rem;
        min-width: 30px;
        max-width: 30px;
      }

      &.text-right {
        justify-content: flex-end;
        text-align: right;
      }

      &.text-center {
        justify-content: center;
        text-align: center;
      }

      & > * {
        position: relative;
        z-index: 2;
      }

      .mat-mdc-icon-button {
        &.sm-icon {
          padding: 0;
          width: 2em;
          height: 2em;
          font-size: 0.7rem;

          .mat-mdc-button-touch-target {
            height: 150%;
            width: 150%;
          }

          .mat-icon {
            height: 1.1em;
            width: 1.1em;
          }
        }
      }

      a { color: currentColor; }

      &.extra-info-column {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        a {
          width: auto;
          &:hover { color: var(--proximilar-accent-color); }
        }

        .bottom-info {
          opacity: 0.6;
          font-size: 0.75em;
          white-space: nowrap;

          @include threeDots(calc(100% - 1rem));
        }
      }
    }

    &:not(.disabled) {
      cursor: pointer;

      .mat-mdc-cell {
        position: relative;
        background-color: var(--proximilar-bg-color);
        transition: background-color 0.3s;
      }

      &.selected, &:hover {
        .mat-mdc-cell {
          background-color: var(--proximilar-accent-light-color);
          // &:before {
          //   opacity: 0.1;
          // }
        }
      }
    }

    &.disabled {
      background-color: var(--proximilar-form-control-disabled-bg-color);
    }
  }

  .mat-mdc-footer-row {
    .mat-mdc-footer-cell {
      padding: 0 0.55rem;
      font-weight: bold;
      background-color: var(--proximilar-accent-light-color);

      &.action-cell, &.info-cell {
        padding: 0.25rem;
        min-width: 30px;
        max-width: 30px;
      }

      &.text-right {
        justify-content: flex-end;
        text-align: right;
      }

      &.text-center {
        justify-content: center;
        text-align: center;
      }
    }
  }

  .action-cell, .info-cell {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    max-width: 40px;
  }

  .mat-mdc-header-row .action-cell > *, .mat-header-row .info-cell > *,
  .mat-mdc-row .action-cell > *, .mat-row .info-cell > * {
    width: auto;
  }

  .info-cell {
    justify-content: flex-start;
  }

  .header-filter-row {
    &:not(.show-filter) {
      min-height: 0;
      height: 0;
      overflow: hidden;
    }

    .filter-ceil {
      width: 100%;

      &.actions-ceil {
        min-width: 100px;
        width: 100px;

        .mat-mdc-button {
          padding: 0;
          min-width: 2rem;
        }
      }

      .mat-mdc-form-field {
        max-width: 100%;
      }

      .filter-number-range {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: right;

        .mat-mdc-form-field {
          max-width: 49%;
          text-align: center;
        }
      }

      .filter-date-range {
        width: 100%;

        .mat-mdc-button { width: 100%; }
      }
    }
  }
}

.mat-mdc-paginator {
  background-color: transparent !important;
}
