.apexcharts-svg { background-color: transparent !important; }

.apexcharts-text { fill: var(--proximilar-font-color); }

.apexcharts-grid .apexcharts-gridlines-horizontal line, .apexcharts-xaxis-tick { stroke: var(--proximilar-font-color); }

/* stylelint-disable-next-line selector-class-pattern */
.apexcharts-menu-item.exportCSV { display: none; }

.chart-tooltip {
  position: absolute;
  visibility: hidden;
  z-index: 10;

  .title {
    display: block;
    padding: 0.3rem;
    color: var(--proximilar-font-color);
    transform: translate(0, calc(-100% - 20px));
    background-color: var(--proximilar-chart-bg-color);
    border-radius: 4px;
  }
}
