.mat-mdc-menu-panel {
  .mat-mdc-menu-item {
    color: var(--proximilar-font-color);

    .mat-icon {
      color: currentColor;
    }

    &.active-link {
      color: var(--proximilar-primary-color);
    }
  }
}
